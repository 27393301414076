<template>
  <div class="code-shell">
    <div class="code"
         ref="code">
      <div id="qrCode"
           ref="qrCodeDiv"></div>
    </div>
    <p style="margin-top:40px">(长按保存二维码)</p>
    <p style="margin-top:20px">提交成功，感谢您的参与</p>
    <div style="color:#aaa;font-size:12px;margin-top:10px"
         v-if="classify==='1'"
         @click="writeAgain('1')">再次填写</div>
    <div style="color:#aaa;font-size:12px;margin-top:10px"
         v-else
         @click="writeAgain('2')">再次填写</div>
    <div style="margin: 40px 20px 0">
      <van-button round
                  block
                  type="info"
                  v-if="classify==='1'"
                  @click="examineFirst">查看填单结果</van-button>
      <van-button round
                  block
                  type="info"
                  v-else
                  @click="examine">查看填单结果</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "qrCode",
  data () {
    return {
      id: '',
      classify: ''
    }
  },
  created () {
    sessionStorage.setItem('id', this.$route.params.id)
    sessionStorage.setItem('classify', this.$route.params.classify)
    this.id = sessionStorage.getItem('id')
    this.classify = sessionStorage.getItem('classify')
    console.log(this.id, this.classify)
  },
  mounted: function () {
    this.$nextTick(function () {
      this.bindQRCode();
    })
  },
  methods: {
    examine () {
      this.$axios2.get(`/registors/zhRegistors/queryVisitorsById?id=${this.id}`).then(res => {
        // console.log(res)
        // console.log(res, this.classify, '22222222222222222')
        let info = JSON.stringify(res.data.result)
        this.$router.push({ name: `openPathForm2`, params: { info: info } })
      })
    },
    examineFirst () {
      this.$axios2.get(`/travelservice/zhTravelservice/queryById?id=${this.id}`).then(res => {
        // console.log(res, this.classify, '11111111111111')
        console.log(res)
        let info = JSON.stringify(res.data.result)
        this.$router.push({ name: `openPathForm`, params: { info: info } })
      })
    },
    bindQRCode: function () {
      let width = this.$refs.code.clientWidth
      // console.log(this.$refs.code.clientWidth + "px")
      // console.log(this.$refs.code.$el.clientHeight + "px")
      console.log(this.classify)
      new this.$qrCode(this.$refs.qrCodeDiv, {
        text: `${this.classify}` === '2' ? `${window.fullPath}` : `${window.fullPathForm}`,
        width: width,
        height: width,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: this.$qrCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    writeAgain (path) {
      if (path === '2')
      {
        this.$router.push('/openPathForm2')
      } else if (path === '1')
      {
        this.$router.push('/openPathForm')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.code-shell {
  padding: 80px 60px 0;
  text-align: center;
  .code {
    // height: 255px;
    // background-color: rgb(124, 124, 124);
    // border-radius: 8px;
    overflow: hidden;
  }
  .van-button {
    height: 40px;
    font-size: 15px;
  }
}
</style>